import React from "react";

function CompromisedCout({
  head1,
  head1Css,
  buttonText,
  buttonStyle,
  buttonUrl,
  targetOpen,
  className,
  referenceContents,
  children,
  ...props
}) {
  return (
    <div
      {...props}
      className={`callout-wrapper cout-compromised ${
        className ? className : ""
      }`}
    >
      {head1 ? (
        <h5 className={head1Css} dangerouslySetInnerHTML={{ __html: head1 }} />
      ) : (
        ""
      )}

      {children}

      <a
        className="button"
        style={buttonStyle && buttonStyle}
        href={buttonUrl}
        dangerouslySetInnerHTML={{ __html: buttonText }}
        target={targetOpen ? targetOpen : ""}
      />

      {referenceContents ? (
        <div
          className="references-wrapper"
          dangerouslySetInnerHTML={{ __html: referenceContents }}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default CompromisedCout;
