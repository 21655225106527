const getUrlFromSlug = (_slug, _array) => {
  let result = "";
  if (_slug && _array) {
    result = _array.filter((item) => item.slug == _slug)[0].pdf.url;
  }
  return result;
};

const sortObjectArrayByKey = (_array, _key) => {
  return _array.sort((a, b) => a[_key] - b[_key]);
};

const getQueryStrings = () => {
  var assoc = {};
  var decode = function (s) {
    return decodeURIComponent(s.replace(/\+/g, " "));
  };
  var queryString = window.location.search.substring(1);
  var keyValues = queryString.split("&");

  for (var i in keyValues) {
    var key = keyValues[i].split("=");
    if (key.length > 1) {
      assoc[decode(key[0])] = decode(key[1]);
    }
  }

  return assoc;
};

const changeUrlWithQstr = (qstr) => {
  // if (window.history.pushState) {
  //   const newurl =
  //     window.location.protocol +
  //     "//" +
  //     window.location.host +
  //     window.location.pathname +
  //     `?tab=${qstr}`;
  //   window.history.pushState({ path: newurl }, "", newurl);
  // }
};

module.exports = {
  getUrlFromSlug,
  sortObjectArrayByKey,
  getQueryStrings,
  changeUrlWithQstr,
};
