import React, { useState, useContext, useEffect } from "react";
import Layout from "layout";
import Hero from "common/hero";
import Seo from "common/seo";
import { Context } from "redux/Store";
import { Row, Col } from "react-grid-system";
import Select from "react-select";
import { sortObjectArrayByKey } from "libs/services/utils";

import { TypeA } from "common/callouts";

import img1 from "images/callouts/cout-footer-science.webp";
import img2 from "images/callouts/cout-footer-baby.webp";
import img3 from "images/callouts/sunproducts2x.webp";

import heroImageRef from "images/resources/hero.webp";

import "./resources.scss";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 63,
    borderRadius: 0,
    backgroundColor: "#F7F7F7",
    border: 0,
    boxShadow: "none",
    // Control custom styles
  }),
  indicatorSeparator: (provided, state) => ({ ...provided, display: "none" }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: "#E8EAEB",
    borderRadius: 0,
    border: 0,
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: 0,
    marginTop: 0,
    backgroundColor: "#E8EAEB",
  }),
};
function ResourcesPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);
  const [currentResources, setCurrentResources] = useState(null);
  const [concernTypeOptions, setConcernTypeOptions] = useState(null);
  const [resourceTypeOptions, setResourceTypeOptions] = useState(null);
  const [optionResource, setOptionResource] = useState(null);
  const [optionConcern, setOptionConcern] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const getAllIds = (_array) => {
    return _array.map((item) => item.id);
  };

  const initResources = () => {
    let _resData = state.sanityData.allResources;
    // console.log("Res,", _resData);
    _resData = _resData.filter((_res) => _res.isActive);
    _resData = sortObjectArrayByKey(_resData, "displayWeight");
    //_resData.sort((a, b) => a.displayWeight - b.displayWeight);
    setCurrentResources(_resData);
  };
  const initConcernTypeOption = () => {
    let _concernTypeOptions = state.sanityData.allConcernTypes;
    _concernTypeOptions = _concernTypeOptions.map((item) => {
      return { ...item, value: item.id, label: item.title };
    });
    _concernTypeOptions = sortObjectArrayByKey(
      _concernTypeOptions,
      "displayWeight"
    );
    //_concernTypeOptions.sort((a, b) => a.displayWeight - b.displayWeight);
    setConcernTypeOptions(_concernTypeOptions);
  };
  const initResourceTypeOptions = () => {
    let _resTypeOptions = state.sanityData.allResourceTypes;
    _resTypeOptions = _resTypeOptions.map((item) => {
      return { ...item, value: item.id, label: item.title };
    });
    _resTypeOptions = sortObjectArrayByKey(_resTypeOptions, "displayWeight");
    //_resTypeOptions.sort((a, b) => a.displayWeight - b.displayWeight);
    setResourceTypeOptions(_resTypeOptions);
  };

  const onClickFilter = () => {
    const _resData = state.sanityData.allResources;
    //console.log(_resData);

    let filteredResult = null;

    if (optionConcern) {
      filteredResult = _resData.filter(
        (item) =>
          getAllIds(item.concernTypeList).includes(optionConcern.id) &&
          item.isActive
      );
    }

    if (optionResource) {
      if (filteredResult) {
        filteredResult = filteredResult.filter(
          (item) =>
            getAllIds(item.resourceTypeList).includes(optionResource.id) &&
            item.isActive
        );
      } else {
        filteredResult = _resData.filter(
          (item) =>
            getAllIds(item.resourceTypeList).includes(optionResource.id) &&
            item.isActive
        );
      }
    }

    if (filteredResult?.length > 0) {
      console.log("filteredResult,", filteredResult);
      filteredResult = sortObjectArrayByKey(filteredResult, "displayWeight");
      //filteredResult.sort((a, b) => a.displayWeight - b.displayWeight);
      setCurrentResources(filteredResult);
      setErrorMessage(null);
    } else {
      initResources();
      if (optionResource || optionConcern) {
        setErrorMessage("No match found. Please revise your selection.");
      } else {
        setErrorMessage(
          "Please select, at least, a Resource type or a Concern."
        );
      }
    }
  };

  const onClickClearAll = () => {
    setOptionResource(null);
    setOptionConcern(null);
    initResources();
    setErrorMessage(null);
  };

  useEffect(() => {
    if (!state.isLoadingData) {
      initResources();
      initResourceTypeOptions();
      initConcernTypeOption();
    }
  }, [state.isLoadingData]);

  const Callouts = () => (
    <>
      <Row>
        <Col sm={12} md={4}>
          <TypeA
            imgRef={img1}
            imgAlt="See our science"
            caption="Experience our legacy of innovation"
            buttonText="See our science"
            buttonUrl="/aboutus"
            imgStyles={{ height: 158 }}
          />
        </Col>
        <Col sm={12} md={4}>
          <TypeA
            imgRef={img2}
            imgAlt="Explore Baby Care"
            caption="Soothe babies’ irritated skin from top to bottom"
            buttonText="Explore Baby Care"
            buttonUrl="/baby"
            imgStyles={{ height: 165 }}
          />
        </Col>
        <Col sm={12} md={4}>
          <TypeA
            imgRef={img3}
            imgAlt="Discover Sun Care"
            caption="Sun protection for all skin types and tones"
            buttonText="Discover Sun Care"
            buttonUrl="/sun"
            imgStyles={{ width: 236 }}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <Layout
      pageClass="page-resources"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo pageTitle="Body" />
      <div className="inner-body-content">
        <section className="inner-centered-container colored-boxes">
          <Row>
            <Col sm={12}>
              <div className="filter-block filter-head-block">
                <h2 className="filter-h2">Filter Resources</h2>
                <button
                  className="button clear-button"
                  onClick={onClickClearAll}
                >
                  Clear all
                </button>
              </div>
              <div className="filter-block filter-selection-block">
                <Select
                  autoFocus={false}
                  defaultValue={optionResource}
                  value={optionResource}
                  onChange={setOptionResource}
                  options={resourceTypeOptions}
                  isSearchable={false}
                  isClearable={false}
                  name="resourceType"
                  className="filter-select"
                  placeholder="Resource type"
                  styles={customStyles}
                />
                <Select
                  autoFocus={false}
                  defaultValue={optionConcern}
                  value={optionConcern}
                  onChange={setOptionConcern}
                  options={concernTypeOptions}
                  isSearchable={false}
                  isClearable={false}
                  name="concernType"
                  className="filter-select"
                  placeholder="Concern"
                  styles={customStyles}
                />
                <button
                  className="button filter-button"
                  onClick={onClickFilter}
                >
                  FILTER
                </button>
              </div>
            </Col>
          </Row>
          {errorMessage && (
            <Row>
              <Col sm={12}>
                <p className="error-msg">{errorMessage}</p>
              </Col>
            </Row>
          )}
          {currentResources && (
            <Row>
              {console.log(currentResources)}
              {currentResources.map((resource) => (
                <Col sm={6} md={6} lg={4} key={resource.id}>
                  <div
                    className={`resource-card resource-card-${resource.mediaType}`}
                    id={`rc-${resource.id}`}
                  >
                    <div className="resource-top">
                      {resource.mediaType == "pdf" ||
                      resource.mediaType == "zip" ? (
                        <a
                          href={`${
                            resource.openExternal
                              ? resource.externalLink
                              : resource.pdf.url
                          }`}
                          download={
                            !resource.openExternal &&
                            resource.pdf.originalFilename
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={resource.thumbnail} alt="" />
                        </a>
                      ) : (
                        <a
                          href={`/resources/video?wistia_id=${resource.wistiaId}`}
                        >
                          <img src={resource.thumbnail} alt="" />
                        </a>
                      )}
                      <div>
                        <p
                          dangerouslySetInnerHTML={{ __html: resource.title }}
                          className="resource-card-title"
                        />
                        <p
                          dangerouslySetInnerHTML={{
                            __html: resource.description,
                          }}
                          className="resource-card-description"
                        />
                      </div>
                    </div>
                    <div className="resource-bottom">
                      {resource.mediaType == "pdf" ||
                      resource.mediaType == "zip" ? (
                        <a
                          href={`${
                            resource.openExternal
                              ? resource.externalLink
                              : resource.pdf.url
                          }`}
                          className="button resource-card-btn"
                          download={
                            !resource.openExternal &&
                            resource.pdf.originalFilename
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {resource.openExternal
                            ? "READ JOURNAL ARTICLE"
                            : "DOWNLOAD NOW"}
                        </a>
                      ) : resource.mediaType == "video" ? (
                        <a
                          href={`/resources/video?wistia_id=${resource.wistiaId}`}
                          className="button resource-card-btn"
                        >
                          WATCH VIDEO
                        </a>
                      ) : (
                        <a
                          href={`/resources/video?wistia_id=${resource.wistiaId}`}
                          className="button resource-card-btn"
                        >
                          PLAY PODCAST
                        </a>
                      )}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Callouts />
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ResourcesPage;

const MyHero = () => (
  <Hero className="inner-hero" bgImagePath={heroImageRef}>
    <div className="inner-hero-content">
      <h1>A suite of resources for you and your patients</h1>
      <p className="hero-reg-text">
        Whether you are looking to support your Eucerin<sup>&reg;</sup> and
        Aquaphor<sup>&reg;</sup> recommendations, take a closer look at
        clinicals, or connect with peers, you will find all of the resources you
        need right here.
      </p>
    </div>
  </Hero>
);
