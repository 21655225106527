import React from "react";
import { Link } from "gatsby";

function TypeA({
  imgRef,
  imgAlt,
  imgStyles,
  caption,
  captionStyle,
  buttonText,
  buttonClass,
  buttonStyle,
  buttonUrl,
  targetOpen,
  extUrlClass,
  isExternalLink,
  className,
  ...props
}) {
  return (
    <div
      {...props}
      className={`callout-wrapper cout-type-a ${className ? className : ""}`}
    >
      <div className="image-wrapper">
        <img
          src={imgRef}
          alt={imgAlt ? imgAlt : ""}
          style={imgStyles && imgStyles}
        />
      </div>
      <p
        className="caption"
        style={captionStyle && captionStyle}
        dangerouslySetInnerHTML={{ __html: caption }}
      />
      {isExternalLink ? (
        <a
          className={`button ${extUrlClass ? extUrlClass : ""} ${
            buttonClass ? buttonClass : ""
          }`}
          style={buttonStyle && buttonStyle}
          href={buttonUrl}
          dangerouslySetInnerHTML={{ __html: buttonText }}
          target={targetOpen ? targetOpen : ""}
        />
      ) : (
        <Link
          className={`button ${extUrlClass ? extUrlClass : ""} ${
            buttonClass ? buttonClass : ""
          }`}
          style={buttonStyle && buttonStyle}
          to={buttonUrl}
          dangerouslySetInnerHTML={{ __html: buttonText }}
        />
      )}
    </div>
  );
}

export default TypeA;
