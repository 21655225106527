import React from "react";
import { Link } from "gatsby";

function TypeC({
  head1,
  //head1Style,
  prodLabel,
  prodLabelCss,
  head1Css,
  regTextCss,
  buttonText,
  buttonStyle,
  buttonUrl,
  targetOpen,
  className,
  isExternalLink,
  children,
  ...props
}) {
  return (
    <div
      {...props}
      className={`callout-wrapper cout-type-c ${className ? className : ""}`}
    >
      {/*  <h5
        className="northern-lights-blue"
        style={head1Style && head1Style}
        dangerouslySetInnerHTML={{ __html: head1 }}
      /> */}

      {prodLabel ? (
        <h3
          className={prodLabelCss}
          dangerouslySetInnerHTML={{ __html: prodLabel }}
        />
      ) : (
        ""
      )}

      {head1 ? (
        <h5 className={head1Css} dangerouslySetInnerHTML={{ __html: head1 }} />
      ) : (
        ""
      )}

      {children}

      {/* {regTextCss ? <p className={regTextCss}> {children} </p> : <p className="reg-text"> {children} </p>} */}
      {isExternalLink ? (
        <a
          className="button"
          style={buttonStyle && buttonStyle}
          href={buttonUrl}
          dangerouslySetInnerHTML={{ __html: buttonText }}
          target={targetOpen ? targetOpen : ""}
        />
      ) : (
        <Link
          className="button"
          style={buttonStyle && buttonStyle}
          to={buttonUrl}
          dangerouslySetInnerHTML={{ __html: buttonText }}
        />
      )}
    </div>
  );
}

export default TypeC;
