import React from "react";
import { Link } from "gatsby";

function TypeB({
  head1,
  head1Style,
  head2,
  head2Style,
  buttonText,
  buttonStyle,
  buttonUrl,
  targetOpen,
  className,
  isExternalLink,
  children,
  ...props
}) {
  return (
    <div
      {...props}
      className={`callout-wrapper cout-type-b ${className ? className : ""}`}
    >
      <p
        className="h1"
        style={head1Style && head1Style}
        dangerouslySetInnerHTML={{ __html: head1 }}
      />
      <p>
        {head2 && (
          <>
            <span
              style={head2Style && head2Style}
              dangerouslySetInnerHTML={{ __html: head2 }}
              className="head2"
            />
            <br />
          </>
        )}
        <span className="reg-text">{children}</span>
      </p>
      {isExternalLink ? (
        <a
          className="button"
          style={buttonStyle && buttonStyle}
          href={buttonUrl}
          dangerouslySetInnerHTML={{ __html: buttonText }}
          target={targetOpen ? targetOpen : ""}
        />
      ) : (
        <Link
          className="button"
          style={buttonStyle && buttonStyle}
          to={buttonUrl}
          dangerouslySetInnerHTML={{ __html: buttonText }}
        />
      )}
    </div>
  );
}

export default TypeB;
